<template>
	<v-app class="no_top_bar">
		<v-main class="bg-gradient">
			<v-slide-x-transition mode="out-in">
				<router-view></router-view>
			</v-slide-x-transition>
			<r-toast></r-toast>
		</v-main>
	</v-app>
</template>

<script>

export default {
	name: "PublicLayout",
}
</script>